/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import URL from "constant/url";
import ServerCategory from "./ServerCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import ProxyCategory from "./ProxyCategory";
import S5ProxyCategory from "./S5ProxyCategory";
import MobileMenu from "layouts/MobileMenu";

export default function Header() {
  const navigate = useNavigate();
  const domain = window.location.hostname;
  // const logo_r = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-r.png`;
  const logo_w = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-w.png`;

  return (
    <>
      <header className="header-section header-gradient">
            <div className="topbar">
                <div className="container">
                    <div className="row align-item-center">
                        <div className="col-md-6">
                            <div className="topbar-left">
                                <p className="mb-0">Liên hệ ngay nhận ưu đãi hấp dẫn <mark>0888193883</mark></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="topbar-right text-end">
                                <a href="https://manage.vpsmanh24h.com">Đăng nhập</a>
                                <a href="https://manage.vpsmanh24h.com/register">Đăng ký</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-nav">
                <div className="container">
                    <div className="nav-menu bg-white">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-6">
                                <div className="logo-wrapper">
                                    <a href="index.html" className="logo-black">
                                      <img src={logo_w} alt="logo" className="logo"/>
                                    </a>
                                    <a href="index.html" className="logo-white">
                                      <img src={logo_w} alt="logo" className="logo"/>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 d-none d-lg-block">
                                <div className="nav-wrapper">
                                <nav>
                                  <ul>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Home)}
                                      >
                                        Trang chủ
                                      </a>
                                    </li>
                                    <ServerCategory />
                                    <DedicatedServerCategory />
                                    <ProxyCategory />
                                    <S5ProxyCategory />
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Guide)}
                                      >
                                        Hướng dẫn
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Agreement)}
                                      >
                                        Điều khoản
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.SLA)}
                                      >
                                        SLA
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate(URL.Contact)}
                                      >
                                        Liên hệ
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-6">
                                <div className="header-right d-flex align-items-center justify-content-end">
                                    <button className="dark-light-switcher me-2" id="theme-switch">
                                        <span className="light-sun"><i className="fa-solid fa-sun"></i></span>
                                        <span className="dark-moon"><i className="fa-solid fa-moon"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
      <MobileMenu />
    </>
  );
}
