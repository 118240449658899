/* eslint-disable jsx-a11y/iframe-has-title */

import URL from "constant/url";
import { useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Footer() {
  const navigate = useNavigate();
  const domain = window.location.hostname;
  const logo_w = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-w.png`;
  return (
    <footer className="footer pt-100">
        <div className="footer-top pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="footer-widget footer-widget-1">
                            <a href="index.html" className="footer-logo"><img src={logo_w} alt="logo" className="logo"/></a>
                            <p className="text-white mt-30">Đơn vị hàng đầu cung cấp các giải pháp hạ tầng IT, điện toán đám mây.</p>
                            <div className="footer-social mt-5">
                                <h5 className="ft-subtitle text-white position-relative d-inline-block">Follow us on</h5>
                                <ul className="social-nav mt-3">
                                    <li><a href="#" className="rounded-circle overflow-hidden position-relative d-flex align-items-center justify-content-center text-white"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#" className="rounded-circle overflow-hidden position-relative d-flex align-items-center justify-content-center text-white"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#" className="rounded-circle overflow-hidden position-relative d-flex align-items-center justify-content-center text-white"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="#" className="rounded-circle overflow-hidden position-relative d-flex align-items-center justify-content-center text-white"><i className="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="footer-widget footer-widget-2 mt-2">
                            <h5 className="text-white position-relative mb-5 widget-title">Đường dẫn</h5>
                            <ul className="footer-nav">
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.Guide); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Hướng dẫn</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.Agreement); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Điều khoản</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.SLA); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>SLA</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.Contact); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Liên hệ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="footer-widget footer-widget-3 mt-2">
                            <h5 className="text-white position-relative mb-5 widget-title">Dịch vụ</h5>
                            <ul className="footer-nav">
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxyVN); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Việt Nam</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxySG); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Singapore (SG)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxyAU); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Úc (AU)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxyUK); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Anh (UK)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxyDE); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Đức (DE)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxyFR); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Pháp (FR)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxyUS); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Mỹ (US)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListProxyCA); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>Proxy Canada (CA)</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="footer-widget footer-widget-3 mt-2">
                            <h5 className="text-white position-relative mb-5 widget-title">Dịch vụ</h5>
                            <ul className="footer-nav">
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerVN); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Việt Nam</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerSG); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Singapore (SG)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerAU); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Úc (AU)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerUK); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Anh (UK)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerDE); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Đức (DE)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerFR); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Pháp (FR)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerNL); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Mỹ (US)</a>
                                </li>
                                <li>
                                    <a style={{ cursor: "pointer" }} onClick={() => {navigate(URL.ListServerUS); window.scrollTo({top: 0, left: 0, behavior: "smooth",});}}>VPS Canada (CA)</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-copyright">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="copyright-txt text-white">
                            <p className="mb-0">Copyright 2022 VPSManh24h. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}
